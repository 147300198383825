import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '.';
// Icons import
import logoDefault from '../../../images/logo-dark.svg';
import duplexBuild from '../../../images/react_icons/duplex_build.svg';
import fileUpload from '../../../images/react_icons/file-upload';
import logoWhite from '../../../images/react_icons/logo-white.svg';
import logoSmBgDark from '../../../images/react_icons/logo-sm-bg-dark.svg';
import globe from '../../../images/react_icons/globe.svg';
import globeWhite from '../../../images/react_icons/globe-white.svg';
import arrowDown from '../../../images/react_icons/arrow-down.svg';
import arrowDownWhite from '../../../images/react_icons/arrow-down-white.svg';
import arrowLeft from '../../../images/react_icons/arrow-left.svg';
import arrowLeftWhite from '../../../images/react_icons/arrow-left-white.svg';
import arrowRightWhite from '../../../images/react_icons/arrow-right-white.svg';
import whatsapp from '../../../images/react_icons/whatsapp.svg';
import phone from '../../../images/react_icons/phone.svg';
import faq from '../../../images/react_icons/faq.svg';
import loggedUser from '../../../images/react_icons/logged_user.svg';
import loggedUserWhite from '../../../images/react_icons/logged_user-white.svg';
import hamburguerMenu from '../../../images/react_icons/hamburguer_menu.svg';
import xMark from '../../../images/react_icons/xmark.svg';
import desktop from '../../../images/react_icons/desktop.svg';
import calendar from '../../../images/react_icons/calendar.svg';
import message from '../../../images/react_icons/message.svg';
import cart from '../../../images/react_icons/cart.svg';
import logout from '../../../images/react_icons/logout.svg';
import search from '../../../images/react_icons/search.svg';
import searchCircle from '../../../images/react_icons/search_circle.svg';
import searchWhite from '../../../images/react_icons/search_white.svg';
import location from '../../../images/react_icons/location.svg';
import instagram from '../../../images/react_icons/instagram.svg';
import facebook from '../../../images/react_icons/facebook.svg';
import linkedin from '../../../images/react_icons/linkedin.svg';
import miniArrowRight from '../../../images/react_icons/mini-arrow-right.svg';
import fbWithFrame from '../../../images/react_icons/fb-with-frame';
import linkedinWithFrame from '../../../images/react_icons/linkedin-with-frame';
import instagramWithFrame from '../../../images/react_icons/instagram-with-frame';
import booking from '../../../images/react_icons/booking.svg';
import explore from '../../../images/react_icons/explore.svg';
import profile from '../../../images/react_icons/profile.svg';
import flask from '../../../images/react_icons/flask.svg';
import heartMedical from '../../../images/react_icons/heart_medical.svg';
import medal from '../../../images/react_icons/medal.svg';
import stopwatch from '../../../images/react_icons/stopwatch.svg';
import checkbox from '../../../images/react_icons/checkbox.svg';
import arrowDatesRight from '../../../images/react_icons/arrow-dates-right.svg';
import arrowDatesRightBlack from '../../../images/react_icons/arrow-dates-right-black.svg';
import forward from '../../../images/react_icons/forward.svg';
import calendarFiltersBlack from '../../../images/react_icons/calendar_filters_black.svg';
import locationFiltersBlack from '../../../images/react_icons/location_outline_filters_black.svg';
import sliderFiltersBlack from '../../../images/react_icons/slider_filters_black.svg';
import calendarFiltersWhite from '../../../images/react_icons/calendar_filters_white.svg';
import locationFiltersWhite from '../../../images/react_icons/location_outline_filters_white.svg';
import sliderFiltersWhite from '../../../images/react_icons/slider_filters_white.svg';
import calendarPlus from '../../../images/react_icons/calendar_plus.svg';
import headphones from '../../../images/react_icons/headphones.svg';
import swatchbook from '../../../images/react_icons/swatchbook.svg';
import wifi from '../../../images/react_icons/wifi.svg';
import quotationMarks from '../../../images/react_icons/quotation_marks.svg';
import quotationMarksLight from '../../../images/react_icons/quotation_marks_light.svg';
import downloadCloud from '../../../images/react_icons/cloud-download.svg';
import brushLine from '../../../images/react_icons/brush-line.svg';
import carFrontView from '../../../images/react_icons/car-front-view.svg';
import cooktop from '../../../images/react_icons/cooktop.svg';
import gym from '../../../images/react_icons/gym.svg';
import locationBookings from '../../../images/react_icons/location-bookings.svg';
import moveInBookings from '../../../images/react_icons/move_in.svg';
import moveOutBookings from '../../../images/react_icons/move_out.svg';
import rooftop from '../../../images/react_icons/rooftop.svg';
import spaPool from '../../../images/react_icons/spa_pool.svg';
import tv from '../../../images/react_icons/tv.svg';
import bookmark from '../../../images/react_icons/bookmark.svg';
import map from '../../../images/react_icons/map.svg';
import commentExclamation from '../../../images/react_icons/comment-exclamation.svg';
import cleaning from '../../../images/react_icons/cleaning.svg';
import constructorIcon from '../../../images/react_icons/constructor.svg';
import laundry from '../../../images/react_icons/laundry.svg';
import googleLogin from '../../../images/react_icons/google_login.svg';
import facebookLogin from '../../../images/react_icons/facebook_login.svg';
import eyePassword from '../../../images/react_icons/eye_password.svg';
import eyeSlashPassword from '../../../images/react_icons/eye_slash_password.svg';
import errorInputIcon from '../../../images/react_icons/error-circle-times.svg';
import successInputIcon from '../../../images/react_icons/checkbox-circle-fill.svg';
import checkoutCalendarIcon from '../../../images/react_icons/checkout_calendar_icon.svg';
import checkoutArrowRight from '../../../images/react_icons/checkout_arrow_right.svg';
import arrowLeftLine from '../../../images/react_icons/arrow-left-line.svg';
import questionCircle from '../../../images/react_icons/question-circle.svg';
import closeIcon from '../../../images/react_icons/close-icon.svg';
import envelope from '../../../images/react_icons/envelope_alt.svg';
import lightbulb from '../../../images/react_icons/lightbulb.svg';
import gastronomy from '../../../images/react_icons/gastronomy.svg';
import culture from '../../../images/react_icons/culture.svg';
import angleDown from '../../../images/react_icons/angle-down.svg';
import checkoutRadioChecked from '../../../images/react_icons/checkout_radio_checked.svg';
import boleto from '../../../images/react_icons/boleto.svg';
import pix from '../../../images/react_icons/pix.svg';
import bankCard from '../../../images/react_icons/bank-card.svg';
import bankLine from '../../../images/react_icons/bank-line.svg';
import calendarLandlord from '../../../images/react_icons/calendar_landlord.svg';
import headphonesLandlord from '../../../images/react_icons/headphones_landlord.svg';
import moneyLandlord from '../../../images/react_icons/money_landlord.svg';
import paintRollerLandlord from '../../../images/react_icons/paint_roller_landlord.svg';
import paperSearchLandlord from '../../../images/react_icons/paper_search_landlord.svg';
import paperWriteLandlord from '../../../images/react_icons/paper_write_landlord.svg';
import paperWriteLightLandlord from '../../../images/react_icons/paper_write_light_landlord.svg';
import userCheckLandlord from '../../../images/react_icons/user_check_landlord.svg';
import userLandlord from '../../../images/react_icons/user_landlord.svg';
import plusIcon from '../../../images/react_icons/plus.svg';
import minusIcon from '../../../images/react_icons/minus.svg';
import successLargeIcon from '../../../images/react_icons/success-large.svg';
import imageSlider from '../../../images/react_icons/image-slider.svg';
import calendarGrey from '../../../images/react_icons/calendar-grey.svg';
import dolarSignRoundedGrey from '../../../images/react_icons/dolar-sign-rounded-grey.svg';
import mapGrey from '../../../images/react_icons/map-grey.svg';
import paintToolGrey from '../../../images/react_icons/paint-tool-grey.svg';
import signContractGrey from '../../../images/react_icons/sign-contract-grey.svg';
import userGrey from '../../../images/react_icons/user-grey.svg';
import user from '../../../images/react_icons/user.svg';
import controlChevronLeft from '../../../images/react_icons/control-chevron-left.svg';
import controlChevronRight from '../../../images/react_icons/control-chevron-right.svg';
import arrowLeftBlack from '../../../images/react_icons/arrow-left-black.svg';
import arrowRightBlack from '../../../images/react_icons/arrow-right-black.svg';
import googleStoreLarge from '../../../images/react_icons/google_store_large.png';
import appleStoreLarge from '../../../images/react_icons/apple_store_large.png';
import property3dtour from '../../../images/react_icons/property/3dtour.svg';
import propertyBath from '../../../images/react_icons/property/bath.svg';
import propertyBed from '../../../images/react_icons/property/bed';
import propertyGallery from '../../../images/react_icons/property/gallery.svg';
import propertyHouse from '../../../images/react_icons/property/house.svg';
import propertyMap from '../../../images/react_icons/property/map.svg';
import propertyParking from '../../../images/react_icons/property/parking_light.svg';
import propertyBedDark from '../../../images/react_icons/property/bed_dark.svg';
import propertyWifi from '../../../images/react_icons/property/wifi_router.svg';
import propertyBathProducts from '../../../images/react_icons/property/bath_products';
import propertyMicrowave from '../../../images/react_icons/property/microwave.svg';
import propertySmartTv from '../../../images/react_icons/property/smart_tv.svg';
import propertyLinenTowels from '../../../images/react_icons/property/linen_towels.svg';
import propertyAcUnit from '../../../images/react_icons/property/ac_unit.svg';
import propertyBrush from '../../../images/react_icons/property/brush.svg';
import propertyCutlery from '../../../images/react_icons/property/cutlery.svg';
import propertyBalcony from '../../../images/react_icons/property/balcony.svg';
import propertyWashingMachine from '../../../images/react_icons/property/washing_machine.svg';
import propertyCoffeeMachine from '../../../images/react_icons/property/coffee_machine.svg';
import propertyGatedBuilding from '../../../images/react_icons/property/gated_building.svg';
import propertyGym from '../../../images/react_icons/property/gym.svg';
import propertyParkingDark from '../../../images/react_icons/property/parking.svg';
import propertyPets from '../../../images/react_icons/property/pets.svg';
import propertyPool from '../../../images/react_icons/property/pool.svg';
import propertySauna from '../../../images/react_icons/property/sauna';
import propertySkyBalcony from '../../../images/react_icons/property/sky_balcony.svg';
import propertyElevator from '../../../images/react_icons/property/elevator.svg';
import propertyDoorman from '../../../images/react_icons/property/doorman.svg';
import propertyCoworking from '../../../images/react_icons/property/coworking.svg';
import propertyBreakfast from '../../../images/react_icons/property/breakfast.svg';
import mapMarkerTabas from '../../../images/react_icons/map_marker_tabas.svg';
import phoneDark from '../../../images/react_icons/phone_dark.svg';
import bag from '../../../images/react_icons/bag.svg';
import messageLines from '../../../images/react_icons/message-lines.svg';
import key from '../../../images/react_icons/key.svg';
import seaView from '../../../images/react_icons/sea-view.svg';
import privatePool from '../../../images/react_icons/private-pool.svg';
import remoteDoorman from '../../../images/react_icons/remote-doorman.svg';
import doorman24h from '../../../images/react_icons/doorman-24h.svg';
import ceilingFan from '../../../images/react_icons/property/ceiling_fan.svg';
import arrowDown2 from '../../../images/react_icons/arrow-down2.svg';
import closeIconBlack from '../../../images/react_icons/close_icon_black.svg';
import doorcode from '../../../images/react_icons/doorcode.svg';
import reservationWifi from '../../../images/react_icons/reservation_wifi.svg';
import emptyReservations from '../../../images/react_icons/empty_reservations.svg';
import copyIconDark from '../../../images/react_icons/copy_icon_dark.svg';
import googlemaps from '../../../images/react_icons/googlemaps.svg';
import applemaps from '../../../images/react_icons/applemaps.svg';
import uber from '../../../images/react_icons/uber.svg';
import shareIcon from '../../../images/react_icons/share_icon.svg';
import shareBookingIcon from '../../../images/react_icons/share_book.svg';
import copyIcon from '../../../images/react_icons/copy_icon.svg';
import login from '../../../images/react_icons/login.svg';
import logoutFi from '../../../images/react_icons/logout_fi.svg';
import whatsappAlt from '../../../images/react_icons/whatsapp_alt.svg';
import whatsappLight from '../../../images/react_icons/whatsapp_light.svg';
import comment from '../../../images/react_icons/comment.svg';
import ellipse from '../../../images/react_icons/ellipse.svg';
import googleOutline from '../../../images/react_icons/google_outline.svg';
import info from '../../../images/react_icons/info-2.svg';
import calendarCheckLine from '../../../images/react_icons/calendar_check_line.svg';
import uncheckedCheckbox from '../../../images/react_icons/unchecked_checkbox.svg';
import checkedCheckbox from '../../../images/react_icons/checked_checkbox.svg';
import bestDeal from '../../../images/react_icons/best_deal.svg';
import exclamationTriangle from '../../../images/react_icons/exclamation_triangle.svg';
import squareSuccess from '../../../images/react_icons/success-square.svg';
import trashRedSquare from '../../../images/react_icons/trash_square_red.svg';
import brushAlt from '../../../images/react_icons/brush_alt.svg';
import crockery from '../../../images/react_icons/crockery.svg';
import commentHeart from '../../../images/react_icons/comment_heart.svg';
import chainLink from '../../../images/react_icons/chain_link.svg';
import blueInfo from '../../../images/react_icons/blue_info.svg';
import successCircleOutline from '../../../images/react_icons/success_circle_outline.svg';
import shareBooking from '../../../images/react_icons/share_booking.svg';
import playground from '../../../images/react_icons/playground.svg';
import arrowRightGray from '../../../images/react_icons/arrow_right_gray.svg';
import filledWheelchair from '../../../images/react_icons/filled_wheelchair.svg';
import wheelchair from '../../../images/react_icons/wheelchair.svg';
import casaTbLogoDefault from '../../../images/react_icons/casatb/casatb_icon.svg';
import whatsappGray from '../../../images/react_icons/casatb/whatsapp_gray.svg';
import phoneGray from '../../../images/react_icons/casatb/phone_gray.svg';
import closeGray from '../../../images/react_icons/casatb/close_gray.svg';

const icons = {
  logo_default: logoDefault,
  logo_white: logoWhite,
  logo_sm_bg_dark: logoSmBgDark,
  bag: bag,
  messageLines: messageLines,
  key: key,
  globe: globe,
  globe_white: globeWhite,
  arrow_down: arrowDown,
  arrow_down_white: arrowDownWhite,
  arrow_dates_right: arrowDatesRight,
  arrow_dates_right_black: arrowDatesRightBlack,
  arrow_left: arrowLeft,
  arrow_left_white: arrowLeftWhite,
  arrow_right_white: arrowRightWhite,
  whatsapp: whatsapp,
  phone: phone,
  faq: faq,
  logged_user: loggedUser,
  profile: profile,
  logged_user_white: loggedUserWhite,
  hamburguer_menu: hamburguerMenu,
  x_mark: xMark,
  checkbox: checkbox,
  flask: flask,
  heart_medical: heartMedical,
  medal: medal,
  stopwatch: stopwatch,
  desktop: desktop,
  calendar: calendar,
  message: message,
  cart: cart,
  logout: logout,
  search: search,
  search_circle: searchCircle,
  search_white: searchWhite,
  location: location,
  instagram: instagram,
  facebook: facebook,
  linkedin: linkedin,
  mini_arrow_right: miniArrowRight,
  fb_with_frame: fbWithFrame,
  linkedin_with_frame: linkedinWithFrame,
  instagram_with_frame: instagramWithFrame,
  booking: booking,
  explore: explore,
  forward: forward,
  calendar_filters_black: calendarFiltersBlack,
  location_filters_black: locationFiltersBlack,
  calendar_filters_white: calendarFiltersWhite,
  location_filters_white: locationFiltersWhite,
  slider_filters_black: sliderFiltersBlack,
  slider_filters_white: sliderFiltersWhite,
  calendar_plus: calendarPlus,
  headphones: headphones,
  swatchbook: swatchbook,
  wifi: wifi,
  quotation_marks: quotationMarks,
  quotation_marks_light: quotationMarksLight,
  download_cloud: downloadCloud,
  brush_line: brushLine,
  car_front_view: carFrontView,
  cooktop: cooktop,
  gym: gym,
  location_bookings: locationBookings,
  move_in_bookings: moveInBookings,
  move_out_bookings: moveOutBookings,
  rooftop: rooftop,
  spa_pool: spaPool,
  tv: tv,
  bookmark: bookmark,
  map: map,
  comment_exclamation: commentExclamation,
  cleaning: cleaning,
  laundry: laundry,
  google_login: googleLogin,
  facebook_login: facebookLogin,
  eye_password: eyePassword,
  eye_slash_password: eyeSlashPassword,
  error_input_icon: errorInputIcon,
  success_input_icon: successInputIcon,
  constructor_icon: constructorIcon,
  checkout_calendar_icon: checkoutCalendarIcon,
  checkout_arrow_right: checkoutArrowRight,
  arrow_left_line: arrowLeftLine,
  question_circle: questionCircle,
  close_icon: closeIcon,
  envelope: envelope,
  angle_down: angleDown,
  checkout_radio_checked: checkoutRadioChecked,
  boleto: boleto,
  pix: pix,
  adyen_card: bankCard,
  wire_transfer: bankLine,
  wire_transfer_international: bankLine,
  calendar_landlord: calendarLandlord,
  headphones_landlord: headphonesLandlord,
  money_landlord: moneyLandlord,
  paint_roller_landlord: paintRollerLandlord,
  paper_search_landlord: paperSearchLandlord,
  paper_write_landlord: paperWriteLandlord,
  paper_write_light_landlord: paperWriteLightLandlord,
  user_landlord: userLandlord,
  user_check_landlord: userCheckLandlord,
  plus_icon: plusIcon,
  minus_icon: minusIcon,
  success_large: successLargeIcon,
  image_slider: imageSlider,
  calendar_grey: calendarGrey,
  dolar_sign_rounded_grey: dolarSignRoundedGrey,
  map_grey: mapGrey,
  paint_tool_grey: paintToolGrey,
  sign_contract_grey: signContractGrey,
  user_grey: userGrey,
  control_chevron_left: controlChevronLeft,
  control_chevron_right: controlChevronRight,
  arrow_left_black: arrowLeftBlack,
  arrow_right_black: arrowRightBlack,
  google_store_large: googleStoreLarge,
  apple_store_large: appleStoreLarge,
  property_3dtour: property3dtour,
  property_bath: propertyBath,
  property_bed: propertyBed,
  property_gallery: propertyGallery,
  property_house: propertyHouse,
  property_map: propertyMap,
  property_parking: propertyParking,
  property_bed_dark: propertyBedDark,
  property_wifi: propertyWifi,
  property_bath_products: propertyBathProducts,
  property_microwave: propertyMicrowave,
  property_smart_tv: propertySmartTv,
  property_linen_towels: propertyLinenTowels,
  property_ac_unit: propertyAcUnit,
  property_brush: propertyBrush,
  property_cutlery: propertyCutlery,
  property_balcony: propertyBalcony,
  property_washing_machine: propertyWashingMachine,
  property_coffee_machine: propertyCoffeeMachine,
  property_gated_building: propertyGatedBuilding,
  property_gym: propertyGym,
  property_parking_dark: propertyParkingDark,
  property_pets: propertyPets,
  property_pool: propertyPool,
  property_sauna: propertySauna,
  property_sky_balcony: propertySkyBalcony,
  property_elevator: propertyElevator,
  property_doorman: propertyDoorman,
  coworking: propertyCoworking,
  breakfast: propertyBreakfast,
  duplex_build: duplexBuild,
  map_marker_tabas: mapMarkerTabas,
  phone_dark: phoneDark,
  user: user,
  sea_view: seaView,
  private_pool: privatePool,
  remote_doorman: remoteDoorman,
  doorman_24h: doorman24h,
  lightbulb: lightbulb,
  gastronomy: gastronomy,
  culture: culture,
  ceiling_fan: ceilingFan,
  empty_reservations: emptyReservations,
  arrow_down2: arrowDown2,
  close_icon_black: closeIconBlack,
  doorcode: doorcode,
  reservation_wifi: reservationWifi,
  copy_icon_dark: copyIconDark,
  googlemaps: googlemaps,
  applemaps: applemaps,
  uber: uber,
  share_icon: shareIcon,
  share_booking_icon: shareBookingIcon,
  copy_icon: copyIcon,
  login: login,
  logout_fi: logoutFi,
  whatsapp_alt: whatsappAlt,
  whatsapp_light: whatsappLight,
  comment: comment,
  ellipse: ellipse,
  google_outline: googleOutline,
  info: info,
  calendar_check_line: calendarCheckLine,
  unchecked_checkbox: uncheckedCheckbox,
  checked_checkbox: checkedCheckbox,
  best_deal: bestDeal,
  exclamation_triangle: exclamationTriangle,
  file_upload: fileUpload,
  square_success: squareSuccess,
  trash_red_square: trashRedSquare,
  brush_alt: brushAlt,
  crockery: crockery,
  comment_heart: commentHeart,
  chain_link: chainLink,
  blue_info: blueInfo,
  success_circle_outline: successCircleOutline,
  share_booking: shareBooking,
  playground: playground,
  arrow_right_gray: arrowRightGray,
  filled_wheelchair: filledWheelchair,
  wheelchair: wheelchair,
  casatb_default_logo: casaTbLogoDefault,
  whatsapp_gray: whatsappGray,
  phone_gray: phoneGray,
  close_gray: closeGray
};

const Icon = styled(Box).attrs({
  component: 'img'
})`
  ${({ size }) => size && css`
    && {
      width: ${size}px;
    }
  `}
`;

const SvgIcon = forwardRef(({ name, size, ...rest }, ref) => {
  return <Icon ref={ref} src={icons[name]} size={size} {...rest} />;
});

export default SvgIcon;
